
















































import { Vue, Component, Prop } from 'vue-property-decorator';
import Button from '../../../buttons/Button.vue';
import Icon from '@/shared/resources/components/Icon.vue';
import Tooltip from '@/shared/resources/components/Tooltip.vue';

@Component({
  components: {
    Tooltip,
    Icon,
    Button,
  },
})
export default class ActionsButton extends Vue {
  /**
   * Props
   */
  @Prop() private icon!: string;
  @Prop() private tooltip?: string;
  @Prop() private to?: object;
  @Prop() private color?: string | null;
  @Prop({ default: false, type: Boolean }) private disabled!: boolean;

  /**
   * Getters
   */
  private get buttonColor(): string {
    return this.color || 'accent lighten-2';
  }

  /**
   * Display getters
   */
  private get displayTooltip(): boolean {
    return !!this.tooltip;
  }
}
