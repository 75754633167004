




































import { Vue, Component, Prop } from 'vue-property-decorator';

import ItemCollection from '@/core/support/collection/ItemCollection';
import ORMCollection from '@/core/bridge/orm/ORMCollection';
import TableTemplateInterface from '@/shared/lib/interfaces/TableTemplateInterface';

import Pagination from '@/shared/resources/components/DataPagination.vue';
import DataTableCurrentLimit from '@/shared/resources/components/tables/data-table/table/tools/DataTableCurrentLimit.vue';
import DataTableLimitSelect from '@/shared/resources/components/tables/data-table/table/tools/DataTableLimitSelect.vue';

@Component({
  components: {
    DataTableLimitSelect,
    DataTableCurrentLimit,
    Pagination,
  },
})
export default class TableToolsBottom extends Vue {
  /**
   * Props
   */
  @Prop() private collection!: ItemCollection | ORMCollection;
  @Prop() private template!: TableTemplateInterface;
  @Prop({ default: false, type: Boolean }) private noPagination!: boolean;
  @Prop() private limit!: number;

  /**
   * Getters
   */
  private get totalGreaterThanMinLimit(): boolean {
    return this.collection.pagination.getTotal() > this.limit;
  }

  /**
   * Disable getters
   */
  private get disableLimitSelect(): boolean {
    return false;
  }

  /**
   * Display getters
   */
  private get displayLimitSelect(): boolean {
    return !!this.template.limitSelect && this.totalGreaterThanMinLimit;
  }

  private get displayCurrentLimit(): boolean {
    return !this.collection.empty() && !!this.template.bottomLimit;
  }

  private get displayPagination(): boolean {
    return !this.collection.empty() &&
      !this.noPagination &&
      !!this.template.bottomPagination &&
      this.totalGreaterThanMinLimit;
  }

  /**
   * Handlers
   */
  private onUpdateLimit(limit: number) {
    this.$emit('update:limit', limit);
  }

  private onUpdatePage(page: number) {
    this.$emit('update:page', page);
  }
}
