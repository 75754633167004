





























import { Vue, Component, Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import DataTable from '@/shared/resources/components/tables/data-table/DataTable.vue';
import ActionsButton from '@/shared/resources/components/tables/data-table/actions/ActionsButton.vue';
import DocumentModel from '@/app/modules/documents/models/DocumentModel';
import ORMCollection from '@/core/bridge/orm/ORMCollection';
import ApiORMQueryBuilderExtended from '@/shared/lib/api/query-builders/ApiORMQueryBuilderExtended';
import ApiSettings from '@/core/api/settings/ApiSettings';
import Locale from '@/core/locale/Locale';
import TableTemplateInterface from '@/shared/lib/interfaces/TableTemplateInterface';

@Component({
  components: {
    ActionsButton,
    DataTable,
  },
})
export default class OnlineOrdersTable extends Vue {
  /**
   * Props
   */
  @Prop() private query!: ApiORMQueryBuilderExtended;

  /**
   * Data
   */
  private template: TableTemplateInterface = {
    search: false,
    // topPagination: false,
    bottomPagination: true,
  };

  private headers: DataTableHeader[] = [
    {
      value: 'number',
      text: Locale.i18n.t('onlineOrders.table.number').toString(),
    },
    {
      value: 'totalsFormattedWithCurrency.total',
      text: Locale.i18n.t('onlineOrders.table.total').toString(),
    },
    {
      value: 'date',
      text: Locale.i18n.t('onlineOrders.table.date').toString(),
    },
    {
      value: 'status',
      text: Locale.i18n.t('onlineOrders.table.status').toString(),
    },
    {
      value: 'actions',
      text: '',
      class: 'table-col-actions',
      sortable: false,
    },
  ];

  /**
   * Methods
   */
  private goToDetails(itemId: number) {
    this.$router.push({ name: 'orders.details', params: { id: itemId.toString() } });
  }

  /**
   * Handlers
   */
  private onClickDetailsAction(document: DocumentModel) {
    this.goToDetails(document.id);
  }

  private onRowClick(row: any) {
    this.goToDetails(row.item.id);
  }

  private onFetch(collection: ORMCollection) {
    this.$emit('fetch', collection);
  }
}
